import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, ListSubheader, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import CopyData from './CopyData';
import InputTags from './InputTags';
import {onlyUnique, requestOptions} from "../../../../../Helpers/function.js"
import api from "../../../../../Apis";
import { useTranslation } from "react-i18next";

export default function FormCreateProduct(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        dialog: false,
        status_edit: false,
        errors: {},
        attributes: [],
        product_details: []
    })
    const {register, handleSubmit, reset, setValue, control, getValues} = useForm({
        defaultValue: {
            product_details: []
        }
    });
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "product_details", // unique name for your Field Array
    });

    useEffect(() => {
        if (props.data_edit != null) {
            setValue('name', props.data_edit.name);
            setValue('code', props.data_edit.code);
            setValue('link', props.data_edit.link);
            setValue('amount', props.data_edit.amount);
            setValue('price', props.data_edit.price);
            setValue('price_root', props.data_edit.price_root);
            setValue('weight', props.data_edit.weight);
            setValue('amount_warning_sold', props.data_edit.amount_warning_sold);
            setValue('attribute_0', props.data_edit.attribute_0);
            setValue('attribute_1', props.data_edit.attribute_1);
            if (props.data_edit.attributes.length > 0) {
                setValue('product_details', props.data_edit.detail_products);
            } else {
                setValue('product_details', []);
            }
            setState({
                ...state,
                attributes: props.data_edit.attributes
            })
        }
    }, [props.data_edit])

    const onAddProductSubmit = (data) => {
        if (state.attributes.length > 0 && data.product_details.length == 0) {
            Swal.fire({
                title: t("product.addProductForAttribute"),
                icon: 'warning',
            })
        } else {
            const api_url = props.data_edit == null ? api.create_product : api.edit_product;
            data['attributes'] = state.attributes;
            data['code'] = props.data_edit == null ? data.code : props.data_edit.code;
            data['product_id'] = props.data_edit == null ? null : props.data_edit.id;
            data["lang"] = i18n.language
            fetch(api_url, requestOptions('POST', props.cookie, data))
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        Swal.fire({
                            title: props.data_edit == null ? t("common.message.addSuccess") : t("common.message.updateSuccess"),
                            icon: 'success',
                            timer: 1000
                        })
                        reset();
                        if (props.data_edit == null) {
                            props.createProductSuccess(result.data);
                        } else {
                            props.updateProduct(result.data, props.stt);
                        }
                    } else {
                        Swal.fire({
                            title: result.message,
                            icon: 'warning',
                        })
                        setState({
                            ...state,
                            errors: result.validates
                        })
                    }
                }
            )
        }
    }
    const deleteProductItem = (index) => {
        const product_detail_copy = getValues('product_details');
        product_detail_copy.splice(index, 1);
        setValue('product_details', product_detail_copy);
        product_detail_copy.map((item) => {
            setValue(`product_details.${index}.price_root`, item.price_root);
            setValue(`product_details.${index}.price`, item.price);
            setValue(`product_details.${index}.weight`, item.weight);
            setValue(`product_details.${index}.amount`, item.amount);
            setValue(`product_details.${index}.amount_warning_sold`, item.amount_warning_sold);
        })
        const product_details = [...state.product_details];
        //if (product_details.length > 1) {
            product_details.splice(index, 1);
            setState({
                ...state,
                product_details: product_details
            })
        //}
    }
    const addProductDetail = () => {
        const data = {
            code: '',
            price_root: 0,
            price: 0,
            weight: 0,
            attribute: '',
            amount_warning_sold: 0,
            amount: 0
        }
        setState({
            ...state,
            product_details: [data, ...state.product_details]
        })
    }

    const deleteAttribute = (index) => {
        const attributes = [...state.attributes];
        attributes.splice(index, 1);
        updateProductDetail(attributes);
    }

    const updateProductDetail = (attributes) => {
        const product_details = [];
        if (attributes.length == 1) {
            attributes[0].value.split(";").map((valueItem, key) => {
                setValue(`product_details.${key}.attribute_0`, key);
                setValue(`product_details.${key}.attribute_1`, null);
                const obj = {
                    price_root: 0,
                    price: 0,
                    weight: 0,
                    attribute: attributes[0].name + ': ' + valueItem,
                    amount: 0,
                    amount_warning_sold: 0,
                    attribute_0: key,//0,
                    attribute_1: null
                }
                product_details.push(obj);
            })
        } else if (attributes.length == 2) {
            const value_attr_1 = attributes[0].value.split(";");
            const value_attr_2 = attributes[1].value.split(";");
            var index = 0;
            for (var i = 0; i < value_attr_1.length; i ++) {
                for (var j = 0; j < value_attr_2.length; j++) {
                    const stt = index++;
                    setValue(`product_details.${stt}.attribute_0`, i);
                    setValue(`product_details.${stt}.attribute_1`, j);
                    product_details.push(
                        {
                            price_root: 0,
                            price: 0,
                            weight: 0,
                            attribute: attributes[0].name + ': ' + value_attr_1[i] + ', ' + attributes[1].name + ': ' + value_attr_2[j],
                            amount: 0,
                            amount_warning_sold: 0,
                            attribute_0: i,
                            attribute_1: j 
                        }
                    )
                }
            }
        }
        setValue('product_details', product_details);
        setState({
            ...state,
            attributes: attributes,
            //product_details: product_details
        })
    }

    const onChangeNameAttr = (e, index, stt) => {
        const attributes = [...state.attributes];
        attributes[stt][index] = e.target.value;
        setState({
            ...state,
            attributes: attributes
        })
    }

    const addAttribnute = () => {
        const data = {name: '', value: ''};
        setState({
            ...state,
            attributes: [...state.attributes, data]
        });
    }

    const handleSelecetedTags = (items) => {

    }

    const onCopyData = (data) => {
        const product_details = getValues('product_details');
        product_details.map((item, index) => {
            item.price_root = data.price_root;
            item.price = data.price;
            item.weight = data.weight;
            item.amount = data.amount;
            item.amount_warning_sold = data.amount_warning_sold;
            setValue(`product_details.${index}.price_root`, data.price_root);
            setValue(`product_details.${index}.price`, data.price);
            setValue(`product_details.${index}.weight`, data.weight);
            setValue(`product_details.${index}.amount`, data.amount);
            setValue(`product_details.${index}.amount_warning_sold`, data.amount_warning_sold);
        })
    }

    return (
        <DialogContent>
            <form>
                <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                    <Grid item xs={6}>
                        <TextField
                            {...register('name')} defaultValue={state.status_edit == true && props.product_edit != '' ? props.product_edit.name : ''}
                            fullWidth
                            size="small"
                            autoComplete='off'
                            error={(state.errors?.name != '' && state.errors?.name != undefined) ? true : false}
                            helperText={(state.errors?.name != '' && state.errors?.name != undefined) ? state.errors.name : ''}
                            margin="dense" required id="outlined-required" label={t("product.label.productName")} variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...register('code')} defaultValue={state.status_edit == true && props.product_edit != '' ? props.product_edit.code : ''}
                            fullWidth
                            size="small"
                            autoComplete='off'
                            error={(state.errors?.code != '' && state.errors?.code != undefined) ? true : false}
                            helperText={(state.errors?.code != '' && state.errors?.code != undefined) ? state.errors.code : ''}
                            margin="dense" required id="outlined-required" label={t("product.label.productCode")} variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            {...register('link')} defaultValue={state.status_edit == true && props.product_edit != '' ? props.product_edit.link : ''}
                            fullWidth
                            size="small"
                            autoComplete='off'
                            error={(state.errors?.link != '' && state.errors?.link != undefined) ? true : false}
                            helperText={(state.errors?.link != '' && state.errors?.link != undefined) ? state.errors.link : ''}
                            margin="dense" id="outlined-required" label={t("product.label.importLink")} variant="outlined"
                        />
                    </Grid>
                </Grid>
                {
                    state.attributes.length == 0
                    &&
                    <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                        <Grid item xs={12}>
                            <TextField
                                {...register('amount_warning_sold')} defaultValue={state.status_edit == true && props.product_edit != '' ? props.product_edit.amount_warning_sold : ''}
                                fullWidth
                                type="number"
                                size="small"
                                autoComplete='off'
                                error={(state.errors?.amount_warning_sold != '' && state.errors?.amount_warning_sold != undefined) ? true : false}
                                helperText={(state.errors?.amount_warning_sold != '' && state.errors?.amount_warning_sold != undefined) ? state.errors.amount_warning_sold : ''}
                                margin="dense" id="outlined-required" label={t("product.label.stockWarningQuantity")} variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                {...register('price_root')} defaultValue={state.status_edit == true && props.product_edit != '' ? props.product_edit.price_root : ''}
                                fullWidth
                                size="small"
                                autoComplete='off'
                                error={(state.errors?.price_root != '' && state.errors?.price_root != undefined) ? true : false}
                                helperText={(state.errors?.price_root != '' && state.errors?.price_root != undefined) ? state.errors.price_root : ''}
                                margin="dense" id="outlined-required" label={t("product.label.importPrice")} variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                {...register('price')} defaultValue={state.status_edit == true && props.product_edit != '' ? props.product_edit.price : ''}
                                fullWidth
                                size="small"
                                autoComplete='off'
                                error={(state.errors?.price != '' && state.errors?.price != undefined) ? true : false}
                                helperText={(state.errors?.price != '' && state.errors?.price != undefined) ? state.errors.price : ''}
                                margin="dense" id="outlined-required" label={t("product.label.sellingPrice")} variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                {...register('amount')} defaultValue={state.status_edit == true && props.product_edit != '' ? props.product_edit.amount : ''}
                                fullWidth
                                size="small"
                                autoComplete='off'
                                error={(state.errors?.amount != '' && state.errors?.amount != undefined) ? true : false}
                                helperText={(state.errors?.amount != '' && state.errors?.amount != undefined) ? state.errors.amount : ''}
                                margin="dense" id="outlined-required" label={t("product.label.quantity")} variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                {...register('weight')} defaultValue={state.status_edit == true && props.product_edit != '' ? props.product_edit.weight : ''}
                                fullWidth
                                size="small"
                                autoComplete='off'
                                error={(state.errors?.weight != '' && state.errors?.weight != undefined) ? true : false}
                                helperText={(state.errors?.weight != '' && state.errors?.weight != undefined) ? state.errors.weight : ''}
                                margin="dense" id="outlined-required" label={t("product.label.weightInGrams")} variant="outlined"
                            />
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("product.table.attribute")}</TableCell>
                                        <TableCell>{t("product.table.value")}</TableCell>
                                        <TableCell align="right">
                                            {
                                                state.attributes.length < 2 &&
                                                <Button
                                                    type="button"
                                                    onClick={addAttribnute}
                                                    variant="contained"
                                                    className="text-transform"
                                                >{t("product.table.addAttribute")}</Button>
                                            }
                                            
                                        </TableCell>
                                    </TableRow>
                                    {
                                        state.attributes.map((attrItem, stt) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>
                                                        <TextField
                                                            onChange={(e) => onChangeNameAttr(e, 'name', stt)}
                                                            fullWidth
                                                            value={attrItem.name}
                                                            size="small"
                                                            placeholder={t("product.label.attributeNameExample")}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            onChange={(e) => onChangeNameAttr(e, 'value', stt)}
                                                            fullWidth
                                                            size="small"
                                                            value={attrItem.value}
                                                            placeholder={t("product.label.valuesSeparatorExample")}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Button
                                                            type="button"
                                                            className="text-transform"
                                                            color="error"
                                                            onClick={() => deleteAttribute(stt)}
                                                            variant="contained"
                                                        >
                                                           {t("common.delete")}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableHead>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colspan="9" align="right">
                                            <div style={{display: 'flex', justifyContent: 'right'}}>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        variant="contained"
                                                        onClick={() => updateProductDetail(state.attributes)}
                                                        color="warning"
                                                        sx={{marginRight: '10px'}}
                                                        className="text-transform"
                                                    >
                                                        {t("product.quickAddByAttribute")}
                                                    </Button>
                                                </div>
                                                <CopyData onCopyData={onCopyData}/>
                                                {/* <div>
                                                    <Button
                                                        type="button"
                                                        onClick={addProductDetail}
                                                        variant="contained"
                                                        color="primary"
                                                        className="text-transform"
                                                    >
                                                        Thêm mẫu mã
                                                    </Button>
                                                </div> */}
                                            </div>
                                            
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t("product.label.importPrice")}</TableCell>
                                        <TableCell>{t("product.label.sellingPrice")}</TableCell>
                                        <TableCell>{t("product.table.attribute")}</TableCell>
                                        <TableCell>{t("product.label.weight")}</TableCell>
                                        <TableCell>{t("product.label.quantity")}</TableCell>
                                        <TableCell>{t("product.label.stockWarningQuantity")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        //state.product_details.map((productItem, index) => {
                                        fields.map((productItem, index) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>
                                                    <input type="hidden" defaultValue={productItem.attribute_0} {...register(`product_details.${index}.attribute_0`)}></input>
                                                    <input type="hidden" defaultValue={productItem.attribute_1} {...register(`product_details.${index}.attribute_1`)}></input>
                                                        <TextField
                                                        size="small"
                                                        {...register(`product_details.${index}.price_root`)}
                                                        defaultValue={productItem.price_root}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                        size="small"
                                                        {...register(`product_details.${index}.price`)}
                                                        defaultValue={productItem.price}
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{width: '200px'}}>
                                                        {
                                                            productItem.attribute
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                        size="small"
                                                        {...register(`product_details.${index}.weight`)}
                                                        defaultValue={productItem.weight}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                        size="small"
                                                        {...register(`product_details.${index}.amount`)}
                                                        defaultValue={productItem.weight}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                        size="small"
                                                        {...register(`product_details.${index}.amount_warning_sold`)}
                                                        defaultValue={productItem.weight}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                        type="button"
                                                        color="error"
                                                        variant="contained"
                                                        className="text-transform"
                                                        //onClick={() => deleteProductItem(index)}
                                                        onClick={() => remove(index)}
                                                        >
                                                            {t("common.delete")}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: '20px'}}>
                        <Button onClick={handleSubmit(onAddProductSubmit)} fullWidth size="large" type="submit" variant="contained">
                            {props.data_edit != null ? t("common.update") : t("common.add")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </DialogContent>
    )
}