import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import api from './../../../../../../Apis'
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import Cart from './Components/Cart';
import { Button, MenuItem } from '@mui/material';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function Index(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        'districts': [],
        'wards': [],
        'provinces': [],
        'customer': {
            name: '',
            phone_number: '',
            province: {name: '', id: ''},
            district: {name: '', id: ''},
            ward: {name: '', id: ''},
            note: '',
            address: ''
        },
        'payment_method': '',
        'transport': '',
        'dialog': false,
        'errors': {}
    })
    const phone_number = useSelector(state => state.phone_number);
    //console.log(phone_number, 'chinh123');
    const changeProvince = (e, value) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                province_id: value.id,
                transport_id: state.transport,
                lang: i18n.language
            })
        };
        fetch(api.get_district, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        districts: result.datas,
                        customer: {
                            ...state.customer,
                            province: {name: value.name, id: value.id},
                            district: {name: '', id: ''},
                            ward: {name: '', id: ''}
                        }
                    });
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                    setState({...state, customer: {...state.customer, province: {name: value.name, id: value.id}}});
                }
            }
        )
    }

    const changeWard = (e, value) => {
        setState({...state, customer: {...state.customer, ward: {name: value.name, id: value.id}}});
    }

    const changeDistrict = (e, value) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                district_id: value.id,
                transport_id: state.transport,
                lang: i18n.language
            })
        };
        fetch(api.get_ward, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        wards: result.datas,
                        customer: {
                            ...state.customer,
                            district: {
                                name: value.name, id: value.id
                            },
                            ward: {name: '', id: ''}
                        }
                    });
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                    setState({...state, customer: {...state.customer, district: {name: value.name, id: value.id}}});
                }
            }
        )
    }

    const onSubmitOrder = (data) => {
        // if (state.customer.name == '' || state.customer.phone == '' || state.customer.province.id == '' || state.customer.district.id == '' || state.customer.ward.id == '' || state.customer.address == '') {
        //     Swal.fire('Cần điên đầy đủ thông tin: tên, SĐT, quận, huyện, địa chỉ', '', 'warning');
        // } else {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({
                    carts: data.carts,
                    extra: data.extra,
                    discount: data.discount,
                    customer: state.customer,
                    conversation_id: props.state_parent.conversation_id,
                    fanpage_id: props.fanpage_id,
                    payment_method: state.payment_method,
                    transport: state.transport,
                    lang: i18n.language
                })
            };
            fetch(api.create_order, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        Swal.fire({
                            title: result.message,
                            icon: 'success',
                            timer: 1500
                        })
                    } else {
                        if (result.type == 'validate') {
                            // var text_error = '';
                            // result.errors.map((value, key) => {
                            //     text_error+= '<p>' + value + '</p>';
                            // })
                            setState({
                                ...state,
                                errors: result.errors
                            })
                            Swal.fire({
                                title: t("liveChat.tabContent.checkFields"),
                                icon: 'warning',
                                timer: 2000
                            })
                        } else {
                            Swal.fire(result.message, '', 'error');
                        }
                    }
                }
            )
        //}
    }
    // useEffect(() => {
    //     const total_order = props.state.orders.length;
    //     console.log(props.state_parent.customer, 'chinh1234');
    //     setState({
    //         ...state,
    //         //customer: total_order > 0 ? props.state.orders[0].customer : props.state_parent.customer,
    //         customer: props.state_parent.customer,
    //         //districts: total_order > 0 ? props.state.orders[0].districts : [],
    //         //wards: total_order > 0 ? props.state.orders[0].wards : [],
    //         //provinces: total_order > 0 ? props.state.orders[0].provinces : props.state.provinces,
    //         districts: props.state_parent.districts,
    //         wards: props.state_parent.wards,
    //         provinces: props.state_parent.provinces,
    //         transport: props.state_parent.transport_id,
    //         payment_method: total_order > 0 ? props.state.orders[0].payment_method : '',
    //     });
    // }, [props.state.orders])
    useEffect(() => {
        if (props.state.conversation.customer == undefined) {
            var customer = {...state.customer};
        } else {
            var customer = props.state.conversation.customer;
        }        
        //if (customer.phone_number == '' || customer.phone_number == null) {
            //customer['phone_number'] = phone_number;
        //}
        
        setState({
            ...state,
            customer: customer,
            districts: props.state.conversation.districts == undefined ? [] : props.state.conversation.districts,
            wards: props.state.conversation.wards == undefined ? [] : props.state.conversation.wards,
            provinces: props.state.conversation.provinces == undefined ? [] : props.state.conversation.provinces,
            transport: props.state.conversation.transport_id == undefined ? '' : props.state.conversation.transport_id,
            payment_method: props.state.orders.length > 0 ? props.state.orders[0].payment_method : '',
        });
    }, [props.state.conversation])

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (state.customer.phone_number != '' || state.customer.phone_number != null) {
    //             setState({
    //                 ...state,
    //                 customer: {...state.customer, phone_number: phone_number}
    //             })
    //         }
    //     }, 500);
        
    // }, [phone_number])

    const changeTransport = (e) => {
        //if (e.target.value != state.transport) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({
                    transport_id: e.target.value,
                    lang: i18n.language
                })
            };
            fetch(api.get_province_by_transport, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        setState({
                            ...state,
                            provinces: result.datas,
                            customer: {
                                ...state.customer,
                                province: {name: '', id: ''},
                                district: {name: '', id: ''},
                                ward: {name: '', id: ''},
                            },
                            transport: e.target.value
                        })
                    } else {
                        Swal.fire({
                            title: result.message,
                            icon: 'warning',
                            timer: 2000
                        })
                    }
                }
            )
       // }
    }

    const updateInfoCustomer = () => {
        const data = state.customer;
        data['conversation_id'] = props.state_parent.conversation_id;
        data['transport_id'] = state.transport;
        data['lang'] = i18n.language;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify(data)
        };
        fetch(api.update_info_customer, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        timer: 2000,
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        timer: 2000,
                        icon: 'error'
                    })
                }
            }
        )
    }
    console.log(state, "state");

    return (
        <div>
            <div className='customer-info'>
                <div className='input-item'>
                    <TextField 
                        required
                        size="small"
                        onChange={(e) => setState({...state, customer: {...state.customer, name: e.target.value}})}
                        style={{width: '100%'}}
                        id="outlined-basic"
                        value={state.customer?.name}
                        label={t("common.label.fullName")}
                        variant="outlined"
                        helperText={state.errors['customer.name']?.message}
                        error={state.errors['customer.name']?.status}
                    />
                </div>
                <div className='input-item'>
                    <TextField
                        required
                        size="small"
                        onChange={(e) => setState({...state, customer: {...state.customer, phone_number: e.target.value}})} style={{width: '100%'}}
                        id="outlined-basic"
                        value={state.customer?.phone_number == null ? phone_number : state.customer?.phone_number}
                        label={t("common.label.phoneNumber")}
                        variant="outlined"
                        helperText={state.errors['customer.phone_number']?.message}
                        error={state.errors['customer.phone_number']?.status}
                    />
                </div>
                <div className='input-item'>
                    <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        fullWidth
                        required
                        label={t("common.label.payment")}
                        onChange={(e) => setState({...state, payment_method: e.target.value})}
                        helperText={state.errors.payment_method?.message}
                        error={state.errors.payment_method?.status}
                        value={state.payment_method}
                    >
                        {
                            props.state_parent.payment_methods.map((value, index) => {
                                return (
                                    <MenuItem value={index}>{value}</MenuItem>
                                )
                            })
                        }
                    </TextField>
                </div>
                <div className='input-item'>
                    <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        onChange={changeTransport}
                        fullWidth
                        label={t("common.label.shippingCarrier")}
                        helperText={state.errors.transport?.message}
                        error={state.errors.transport?.status}
                        value={state.transport}
                        required
                    >
                        {
                            props.state_parent.transports.map((value, index) => {
                                return (
                                    <MenuItem value={index}>{value}</MenuItem>
                                )
                            })
                        }
                    </TextField>
                </div>
                <div className='input-item'>
                    <Autocomplete
                        id="size-small-outlined"
                        size="small"
                        options={state.provinces}
                        value={{name: state.customer?.province.name || "", id: state.customer?.province.id || ""}}
                        //value={props.state_parent.customer.province}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option) => option.id}
                        onChange={changeProvince}
                        renderInput={(params) => (
                        <TextField
                            required {...params}
                            label={t("common.label.provinceCity")}
                            helperText={state.errors['customer.province.id']?.message}
                            // helperText={state.errors['customer.province.id'] != undefined ? state.errors['customer.province.id'].message : t('liveChat.tabContent.selectShippingCarrier')}
                            error={state.errors['customer.province.id']?.status}
                        />
                        )}
                    />
                </div>
                <div className='input-item'>
                    <Autocomplete
                        id="size-small-outlined"
                        size="small"
                        options={state.districts}
                        value={{name: state.customer?.district.name || "", id: state.customer?.district.id || ""}}
                        //value={props.state_parent.customer.district}
                        getOptionLabel={(option) => option.name}
                        onChange={changeDistrict}
                        renderInput={(params) => (
                            <TextField
                                required {...params}
                                label={t("common.label.district")}
                                helperText={state.errors['customer.district.id']?.message}
                                error={state.errors['customer.district.id']?.status}
                            />
                        )}
                    />
                </div>
                <div className='input-item'>
                    <Autocomplete
                        id="size-small-outlined"
                        size="small"
                        options={state.wards}
                        value={{name: state.customer?.ward.name || "", id: state.customer?.ward.id || ""}}
                        //value={props.state_parent.customer.ward}
                        onChange={changeWard}
                        helperText={state.errors['customer.ward.id']?.message}
                        error={state.errors['customer.ward.id']?.status}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                        <TextField
                            required {...params}
                            label={t("common.label.wardCommune")}
                            helperText={state.errors['customer.ward.id']?.message}
                            error={state.errors['customer.ward.id']?.status}
                        />
                        )}
                    />
                </div>
                <div className='input-item'>
                    <TextField onChange={(e) => setState({...state, customer: {...state.customer, address: e.target.value}})} style={{width: '100%'}} value={state.customer?.address == null ? '' : state.customer?.address} size="small" id="outlined-basic" label={t("common.label.address") + "*"} variant="outlined" />
                </div>
                <div className='input-item input-textarea' style={{marginBottom: '0px'}}>
                    <textarea
                        onChange={(e) => setState({...state, customer: {...state.customer, note: e.target.value}})}
                        placeholder={t("order.label.note")}
                        value={state.customer.note == undefined ? '' : state.customer.note}
                    >
                    </textarea>
                </div>
                <p className="t-right w-100">
                    <Button onClick={updateInfoCustomer} startIcon={<SaveRoundedIcon/>} variant='contained' color="success" className="text-transform">
                        {t("liveChat.tabContent.updateInformation")}
                    </Button>
                </p>
            </div>
            <Cart 
                onSubmitOrder={onSubmitOrder}
                cookie={props.cookie}
                //products={props.state_parent.products}
                payment_methods={props.state_parent.payment_methods}
                transports={props.state_parent.transports}
            />
        </div>
    )
}

export default Index;