import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FacebookLogin from 'react-facebook-login';
import {
    useNavigate, NavLink, useSearchParams
} from "react-router-dom";
import api from '../../../Apis';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import Swal from "sweetalert2";
import LoadingButton from "@mui/lab/LoadingButton";
import Copyright from "../Copyright/Index";
import {domain_origin} from './../../../Helpers/function.js'
import { Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import LoginWithFacebook from "../../Globals/LoginWithFacebook";
import { initializeApp } from "firebase/app";
import { RecaptchaVerifier, signInWithPhoneNumber, getAuth } from 'firebase/auth';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation, Trans } from "react-i18next";

function componentClicked() {

}

// Initialize Firebase
// const firebaseConfig = {
//     apiKey: "AIzaSyBDK6WoMxc7AyWjp4qWS60c_r_TzZcRVo4",
//     authDomain: "page5s.firebaseapp.com",
//     projectId: "page5s",
//     storageBucket: "page5s.appspot.com",
//     messagingSenderId: "810992804677",
//     appId: "1:810992804677:web:e54ad8fc4e53a1c545142c",
//     measurementId: "G-Q4X4ZBZ818"
// };
  
//   const firebaseApp = initializeApp(firebaseConfig);
//   const auth = getAuth(firebaseApp);
const theme = createTheme();

export default function SignIn() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['is_login', 'source']);
    let date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 3600 * 1000);
    const [searchParams, setSearchParams] = useSearchParams();
    if (searchParams.get('source') != null) {
        setCookie('source', searchParams.get('source'), { path: '/', expires: date, domain: `.${ process.env.REACT_APP_DOMAIN }`});
    }
    const [state, setState] = useState({
        error: {},
        loading_login: false,
        loading_page: false,
        open_dialog: true
    })

   

    // const generateRecaptcha = () => {
    //     window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
    //       'size': 'invisible',
    //       'callback': (response) => {
    //         // reCAPTCHA solved, allow signInWithPhoneNumber.
    //         // ...
    //       }
    //     }, auth);
    // }

    // const senOtp = (event) => {
    //     event.preventDefault();
    //     generateRecaptcha();
    //     let appVerifier = window.recaptchaVerifier;
    //     signInWithPhoneNumber(auth, "+84963108271", appVerifier)
    //     .then((confirmationResult) => {
    //         // SMS sent. Prompt user to type the code from the message, then sign the
    //         // user in with confirmationResult.confirm(code).
    //         window.confirmationResult = confirmationResult;
    //     }).catch((error) => {
    //         // Error; SMS not sent
    //     });
    // }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setState({
            ...state,
            loading_login: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: data.get('username'),
                password: data.get('password'),
                lang: i18n.language
            })
        };
        fetch(api.login, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    let date = new Date();
                    date.setTime(date.getTime() + result.expires_in * 1000);
                    if (process.env.NODE_ENV == 'production') {
                        setCookie('is_login', result.access_token, { path: '/', expires: date, domain: '.' + result.domain});
                    } else {
                        setCookie('is_login', result.access_token, { path: '/', expires: date});
                    }
                    if (result.user.username == null || result.user.phone == null || result.user.email == null) {
                        let route = '/' + result.user.fb_id + '/update-login';
                        return navigate(route);
                    }
                    //return navigate('/dashboard');
                    window.location.href = result.url_redirect + 'dashboard';
                    //return navigate(result.url_redirect + 'dashboard');
                } else {
                    if (result.type == 'validate') {
                        setState({
                            ...state,
                            error: result.errors,
                            loading_login: false
                        })
                    } else if (result.type == 'lose') {
                       setState({
                           ...state,
                           error: {},
                           loading_login: false
                       })
                       Swal.fire({
                           title: result.message,
                           icon: 'error',
                           timer: 2000
                       })
                    }
                }
            }
        )
    };

    function responseFacebook(response) {
        if (response.userID != undefined) {
            setState({
                ...state,
                loading_page: true
            })
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: response.email,
                    full_name: response.name,
                    fb_id: response.userID,
                    avatar: response.picture.data.url,
                    accessToken: response.accessToken,
                    lang: i18n.language
                })
            };
            fetch(api.register, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        let date = new Date();
                        date.setTime(date.getTime() + result.expires_in * 1000);
                        
                        if (result.user.username == null || result.user.phone == null || result.user.email == null) {
                            let route = '/' + result.user.fb_id + '/update-login';
    
                            return navigate(route);
                        } else {
                            setCookie('is_login', result.access_token, { path: '/', expires: date, domain: '.' + result.domain});
                            //setCookie('is_login', result.access_token, { path: '/', expires: date});
                            window.location.href = result.url_redirect + 'dashboard';
                            // return navigate('/dashboard');
                        }
                    } else {
                        setState({
                            ...state,
                            loading_page: false
                        })
                        Swal.fire({
                            title: result.message,
                            icon: 'error'
                        })
                    }
                }
            )
        }
        
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                lang: i18n.language
            })
        };
        fetch(api.check_token, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    window.location.href = result.url_redirect + 'dashboard';
                }
            }
        )
    }, [])

    const domain = window.location.origin;

    if (domain != domain_origin && process.env.NODE_ENV == 'production') {
        window.location.href = domain_origin;
    }

    return (
        <ThemeProvider theme={theme}>
            {
                process.env.REACT_APP_DOMAIN != "page5s.com" &&
                <Dialog
                    open={state.open_dialog}
                    onClose={() => setState({...state, open_dialog: false})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Page5s
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {/* Những khách hàng mới vui lòng truy cập <a href="https://app.page5s.com">https://app.page5s.com</a> để sử dụng phần mềm. */}
                        <Trans 
                            i18nKey={"auth.newCustomersVisit"}
                            components={{
                                a: <a href="https://app.page5s.com"></a>
                            }}
                        />
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => window.location.href = "https://app.page5s.com"}>{t("auth.visitNow")}</Button>
                    <Button onClick={() => setState({...state, open_dialog: false})}>
                       {t("common.close")}
                    </Button>
                    </DialogActions>
                </Dialog>
            }
            {
                state.loading_page &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={state.loading_page}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    {/* <Avatar> */}
                        {/* <LockOutlinedIcon /> */}

                    <img src={`https://${ process.env.REACT_APP_DOMAIN }/assets/img/logo/logo.png`} style={{width: '50%'}} className='logo'></img>
                    {/* </Avatar> */}
                    <Typography component="h1" variant="h5" sx={{
                        marginTop: '10px'
                    }}>
                        {t("auth.title")}
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            helperText={state.error.username?.message}
                            error={state.error.username?.status}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t("auth.password")}
                            type="password"
                            id="password"
                            helperText={state.error.password?.message}
                            error={state.error.password?.status}
                            autoComplete="current-password"
                        />
                        {/* <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="phone"
                            label="Phone"
                            helperText={state.error.password?.message}
                            error={state.error.password?.status}
                            autoComplete="current-password"
                        /> */}
                        <FormControlLabel
                            control={
                                <Checkbox value="remember" defaultChecked color="primary" />
                            }
                            label={t("auth.remember")}
                        />
                        <LoadingButton loading={state.loading_login}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="success"
                            size="large"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {t("auth.title")}
                        </LoadingButton>
                        <p className="m-t-0">
                           {/* <FacebookLogin
                                appId="1121866955234332"
                                autoLoad={false}
                                fields="name,email,picture"
                                scope="pages_show_list,pages_read_engagement,email,pages_manage_posts,pages_read_user_content,pages_manage_engagement,pages_messaging,pages_manage_metadata,instagram_basic,instagram_manage_messages,instagram_manage_comments"
                                onClick={componentClicked}
                                callback={responseFacebook}
                                size="small"
                                textButton="Đăng nhập với Facebook"
                                cssClass="css-fb-custom"
                            />  */}
                            <LoginWithFacebook
                                page="login"
                                title={t("auth.loginWithFacebook")}
                                customClass="css-fb-custom2"
                                size="large"
                            />
                        </p>                        
                        <p className="m-t-0">
                           {/* <FacebookLogin
                                appId="1121866955234332"
                                autoLoad={false}
                                fields="name,email,picture"
                                scope="pages_show_list,pages_read_engagement,email,pages_manage_posts,pages_read_user_content,pages_manage_engagement,pages_messaging,pages_manage_metadata,instagram_basic,instagram_manage_messages,instagram_manage_comments"
                                onClick={componentClicked}
                                callback={responseFacebook}
                                size="small"
                                textButton="Đăng nhập với Facebook"
                                cssClass="css-fb-custom"
                            />  */}
                            <LoginWithFacebook
                                page="login"
                                title={t("auth.registerWithFacebook")}
                                customClass="css-fb-custom"
                                size="large"
                            />
                        </p>                        
                        <Grid container>
                            <Grid item xs>
                                <NavLink className="a-none" to="forget-password" variant="body2">
                                    {t("auth.forgotPassword")}
                                </NavLink>
                            </Grid>
                            {/* <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}
