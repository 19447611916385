import LoadingButton from "@mui/lab/LoadingButton";
import { Autocomplete, Button, Checkbox, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { requestOptions } from "../../../../../../Helpers/function";
import api from './../../../../../../Apis'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
    Link
} from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function FormSearch(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        fanpage_id: props.fanpage_id,
        dialog: false,
        loading_submit: false,
        fanpage_selected: []
    })

    const {register, handleSubmit, setValue, control, reset} = useForm();

    const handleChangeFanpage = (event, data) => {
        setState({
            ...state,
            fanpage_id: data.fanpage_id
        })
    }

    const search = () => {
        props.search(state.fanpage_id);
    }

    const splitFanpage = (data) => {
        data["fanpage_selected"] = state.fanpage_selected;
        data["lang"] = i18n.language;
        setState({
            ...state,
            loading_submit: true
        })
        fetch(api.split_fanpage, requestOptions('POST', props.cookie, data))
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        time: 2000,
                        icon: 'success'
                    })
                    props.updateGroupPage(result.group_pages);
                    setState({
                        ...state,
                        loading_submit: false,
                        dialog: false
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        time: 2000,
                        icon: 'warning'
                    })
                    setState({
                        ...state,
                        loading_submit: false,
                    })
                }
                
            }
        )
    }

    const onCheckedFanpage = (e, data) => {
        setState({
            ...state,
            fanpage_selected: data
        })
    }    
    return (
        <Grid container spacing={2}>
            <Dialog open={state.dialog} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog: false})}}>
                <DialogTitle>{t("post.groupPages")}</DialogTitle>
                <DialogContent>
                    <p>
                        {/* <b style={{color: 'red'}}>Chú ý: </b> Việc phân nhóm page sẽ giúp bạn giảm thiểu trường hợp các bài viết không được publish lên Fanpage Facebook. */}
                        <Trans
                            i18nKey={"post.groupPagesNotice"}
                            components={{ b: <b style={{color: 'red'}} /> }}
                        />
                    </p>
                    <p>
                        {/* Tổng số Page hiện tại của bạn là <b>{props.data_api?.fanpages.length} page</b> */}
                        <Trans
                            i18nKey={"post.totalPages"}
                            components={{ b: <b /> }}
                            values={{count: props.data_api?.fanpages.length}}
                        />
                    </p>
                    <form onSubmit={handleSubmit(splitFanpage)}>
                        <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                            <Grid item xs={12}>
                                <TextField {...register('group_name')} type="text" fullWidth  id="outlined-basic" label={t("post.groupName")} variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    onChange={onCheckedFanpage}
                                    options={props.data_api?.fanpages?.filter(fanpage => fanpage.group_name === null)}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => `${option.name} - ${option.fanpage_id}`}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {`${option.name} - ${option.fanpage_id}`}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t("post.selectFanpage")} placeholder="Chọn Fanpage" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton className="text-transform" type="submit" size="large" fullWidth loading={state.loading_submit} variant="contained">
                                    {t("post.confirmGroupPages")}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <Grid item xs={2} md={3}>
                <Autocomplete
                    id="tags-outlined"
                    options={props.data_api?.fanpages}
                    getOptionLabel={(option) => `${option.name} (${option.fanpage_id})`}
                    defaultValue={props.data_api.fanpage}
                    onChange={handleChangeFanpage}
                    filterSelectedOptions
                    size="small"
                    renderInput={(params) => (
                    <TextField
                        size="small"
                        {...params}
                        label={t("post.selectFanpage")}
                    />
                    )}
                />
                {/* <TextField
                    id="outlined-select-currency"
                    select
                    size="small"
                    fullWidth
                    label="Chọn Fanpage"
                    defaultValue={id}
                    {...registerSearch('fanpage_id')}
                >
                    {
                        state.data_api?.fanpages.map((value, index) => {
                            return (
                                <MenuItem value={value.fanpage_id}>{value.name}</MenuItem>
                            )
                        })
                    }
                </TextField> */}
                {/* <TextField select fullWidth size="small" id="outlined-basic" label="Chọn Fanpage" variant="outlined" /> */}
            </Grid>
            <Grid item xs={3} md={4}>
                <Button className="text-transform" onClick={search} type="submit" variant="contained" color="primary">{t('common.search')}</Button>
                <Button className="text-transform" sx={{marginLeft: '10px'}} onClick={() => setState({...state, dialog: true})} type="submit" variant="contained" color="success">{t("post.groupPages")}</Button>
                <Link to="/dashboard/fanpage/group">
                    <Button className="text-transform" sx={{marginLeft: '10px'}} type="submit" variant="contained" color="warning">{t("post.pageGroupList")}</Button>
                </Link>
            </Grid>
        </Grid>
    )
}