import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    useNavigate, useParams, Link
} from "react-router-dom";
import api from '../../../Apis';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import Swal from "sweetalert2";
import LoadingButton from "@mui/lab/LoadingButton";
import Copyright from "../Copyright/Index";
import { useForm } from "react-hook-form";
import { Backdrop, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import {domain_origin} from './../../../Helpers/function.js'
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";

function componentClicked() {

}
const theme = createTheme();

export default function UpdateAfterLogin() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const { uid } = useParams();
    const [cookies, setCookie] = useCookies(['is_login']);
    const {register, handleSubmit, reset, setValue} = useForm({
        defaultValues: {
            email: '',
            username: '',
            phone: '',
            password: '',

        }
    });
    const [state, setState] = useState({
        error: {},
        loading_login: false,
        loading_page: true,
        user: {
            username: '',
            email: '',
            phone: ''
        },
        term: '',
        dialog: false
    })

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                fb_id: uid,
                lang: i18n.language
            })
        };
        fetch(api.user_profile, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    if (result.data.email != null && result.data.username != null && result.data.phone != null) {
                        return navigate('/');
                    }
                    setValue('email', result.data.email);
                    setValue('username', result.data.username);
                    setValue('phone', result.data.phone);
                    setState({
                        ...state,
                        user: result.data,
                        term: result.term,
                        loading_page: false
                    })
                }
            }
        )
    }, [])

    const domain = window.location.origin;

    if (domain != domain_origin) {
        window.location.href = domain_origin + "/" + uid + '/update-login';
    }

    const updateInfo = (data) => {
        data['fb_id'] = uid;
        setState({
            ...state,
            loading_login: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        };
        fetch(api.update_user, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    let date = new Date();
                    date.setTime(date.getTime() + result.expires_in * 1000);
                    setCookie('is_login', result.access_token, { path: '/', expires: date, domain: '.' + result.domain});
                    /**
                     * Khởi tạo dispatch để chuẩn bị bắn dữ liệu lên redux
                     * Tạo 1 action với hàm profile trong UserAction để trả về payload với 1 object mới
                     */
                    // const action = profile({
                    //     name: result.user.full_name,
                    //     email: result.user.email,
                    //     username: result.user.username
                    // })
                    //dispatch(action);
                    window.location.href = result.url_redirect + 'dashboard';
                    //return navigate('/dashboard');
                } else {
                    if (result.type == 'validate') {
                        setState({
                            ...state,
                            error: result.errors,
                            loading_login: false,
                        })
                    } else if (result.type == 'lose') {
                       setState({
                           ...state,
                           error: {},
                           loading_login: false
                       })
                       Swal.fire({
                           title: result.message,
                           icon: 'error',
                           timer: 2000
                       })
                    }
                }
            }
        )
    };

    return (
        <ThemeProvider theme={theme}>
            {
                state.loading_page
                ?
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={state.loading_page}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {/* <Avatar> */}
                            {/* <LockOutlinedIcon /> */}
                            <img src={`https://${ process.env.REACT_APP_DOMAIN }/assets/img/logo/logo.png`} style={{width: '50%'}} className='logo'></img>
                        {/* </Avatar> */}
                        <br></br>
                        <Typography component="h1" variant="h5" sx={{
                            marginTop: "10px"
                        }}>
                           {t("auth.updateInformation")}
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={handleSubmit(updateInfo)}
                            noValidate
                            sx={{ mt: 1 }}
                        >
                            <TextField
                                disabled={state.user.email != null ? true : false}
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                autoComplete="off"
                                name="email"
                                {...register('email')}
                                helperText={state.error.email?.message}
                                error={state.error.email?.status}
                            />
                            <TextField
                                margin="normal"
                                required
                                disabled={state.user.username != null ? true : false}
                                fullWidth
                                id="email"
                                label="Username"
                                {...register('username')}
                                autoComplete="off"
                                name="username"
                                helperText={state.error.username?.message}
                                error={state.error.username?.status}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                {...register('phone')}
                                id="email"
                                label={t("common.label.phoneNumber")}
                                name="phone"
                                helperText={state.error.phone?.message}
                                error={state.error.phone?.status}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t("auth.password")}
                                type="password"
                                id="password"
                                helperText={state.error.password?.message}
                                error={state.error.password?.status}
                                autoComplete="off"
                                {...register('password')}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password_confirmation"
                                label={t("auth.confirmPassword")}
                                type="password"
                                id="password"
                                autoComplete="off"
                                {...register('password_confirmation')}
                            />
                            <FormGroup>
                                <FormControlLabel helperText={state.error.policy?.message}
                                error={state.error.policy?.status} {...register('policy')} control={<Checkbox defaultChecked />} label={t("auth.agreeTerms")} />
                            </FormGroup>
                            <p className="m-b-0 m-t-0">
                                <a onClick={() => setState({...state, dialog: true})} className="cursor">{t("auth.termsDetails")}</a>
                            </p>
                            <LoadingButton loading={state.loading_login}
                                type="submit"
                                fullWidth
                                variant="contained"
                                size="large"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {t("auth.updateInformation")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
            }
            <Dialog open={state.dialog} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog: false})}}>
                <DialogTitle className="m-b-0">{t("auth.termsOfUse")}</DialogTitle>
                <DialogContent>
                    {parse(state.term)}
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
}
