import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DoneIcon from "@mui/icons-material/Done";
import Box from "@mui/material/Box";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchFanpageForm from "./SearchFanpageForm";

const DialogSelectFanpages = ({ listFanpages, handleChangeFanpage }) => {
  // console.log(listFanpages, "select fanpages cpn");
  const { t } = useTranslation();

  const checkAllOption = {
    fanpage_id: -1,
    name: t("common.all"),
  };

  const [state, setState] = useState({
    dialog: false,
    listFanpages: listFanpages,
    fanpage_selected: [],
    all_checked: false,
    isSearching: false,
    limit_fanpage: 20,
  });

  const handleChange = (event, value) => {
    const { checked } = event.target;
    if (checked) {
      setState((prevState) => {
        return {
          ...prevState,
          fanpage_selected:
            [...prevState.fanpage_selected, value].length ===
            listFanpages.length
              ? [checkAllOption, ...prevState.fanpage_selected, value]
              : [...prevState.fanpage_selected, value], // khi chọn hết thì sẽ thêm all vào
          all_checked:
            [...prevState.fanpage_selected, value].length ===
            listFanpages.length, // khi chọn hết thì check all sẽ checked
        };
      });
      return;
    }
    setState((prevState) => {
      if (state.all_checked) {
        return {
          ...prevState,
          fanpage_selected: prevState.fanpage_selected.filter(
            (fanpage) =>
              fanpage.fanpage_id !== value.fanpage_id &&
              fanpage.fanpage_id !== checkAllOption.fanpage_id
          ),
          all_checked: false,
        };
      }
      return {
        ...prevState,
        fanpage_selected: prevState.fanpage_selected.filter(
          (fanpage) => fanpage.fanpage_id !== value.fanpage_id
        ),
      };
    });
  };

  const handleSelectAll = (event) => {
    const { checked } = event.target;
    if (checked) {
      setState((prevState) => ({
        ...prevState,
        fanpage_selected: [checkAllOption, ...prevState.listFanpages.slice(0, state.limit_fanpage)],
        all_checked: true,
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      fanpage_selected: [],
      all_checked: false,
    }));
  };
  // console.log(state.fanpage_selected.length, "selected length");

  const handleConfirm = () => {
    handleChangeFanpage(state.fanpage_selected);
    setState({ ...state, dialog: false, isSearching: false });
  };

  const handleSearch = (searchTerm) => {
    const filteredFanpages = listFanpages.filter((fanpage) =>
      fanpage.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setState({
      ...state,
      listFanpages: filteredFanpages,
      isSearching: searchTerm.trim() !== "",
      // fanpage_selected: [],
      // all_checked: false,
    });
  };

  const handleClearSearch = () => {
    setState({
      ...state,
      listFanpages: listFanpages,
      isSearching: false,
      // fanpage_selected: [],
      // all_checked: false,
    });
  };

  const handleLoadMoreFanpages = () => {
    setState((prevState) => ({
      ...prevState,
      limit_fanpage: prevState.limit_fanpage + 20,
      fanpage_selected: prevState.fanpage_selected.filter(item => item.fanpage_id !== checkAllOption.fanpage_id),
      all_checked: false,
    }));
  }
  // console.log(state.fanpage_selected, "selected fanpages");
  // console.log(state.all_checked, "all checked");
  return (
    <div>
      <Button
        startIcon={<AddRoundedIcon />}
        variant="contained"
        className="text-transform"
        onClick={() => {
          setState({
            ...state,
            listFanpages: listFanpages,
            dialog: true,
            // all_checked: state.fanpage_selected.length === listFanpages.length,
          });
        }}
      >
        {t("post.selectFanpage")}
      </Button>

      {/* <Dialog here*/}
      <Dialog
        open={state.dialog}
        fullWidth
        maxWidth="md"
        onClose={handleConfirm}
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "8px",
          }}
        >
          {t("post.fanpageList")}
          <SearchFanpageForm
            onSearch={handleSearch}
            onClear={handleClearSearch}
          />
        </DialogTitle>
        <DialogContent>
          {state.listFanpages.length > 0 ? (
            <Fragment>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr", // 2 cột đều nhau
                  gap: "8px", // Khoảng cách giữa các item
                }}
              >
                {!state.isSearching && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.all_checked}
                        onChange={(e) => handleSelectAll(e, checkAllOption)}
                        name="selectAll"
                      />
                    }
                    label={t("common.all")}
                  />
                )}

                {state.listFanpages
                  .slice(0, state.limit_fanpage)
                  .map((fanpage) => (
                    <FormControlLabel
                      key={fanpage.fanpage_id}
                      control={
                        <Checkbox
                          checked={
                            state.fanpage_selected.findIndex(
                              (item) => item.fanpage_id === fanpage.fanpage_id
                            ) !== -1
                          }
                          onChange={(e) => {
                            handleChange(e, fanpage);
                          }}
                          // name={item}
                          // value={fanpage}
                        />
                      }
                      label={`${fanpage.name} - ${fanpage.fanpage_id}`}
                    />
                  ))}
              </Box>
              {state.listFanpages.length > state.limit_fanpage && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "16px",
                  }}
                >
                  <Button
                    // fullWidth
                    className="text-transform"
                    variant="contained"
                    autoFocus
                    // disabled={state.fanpage_selected.length === 0}
                    onClick={handleLoadMoreFanpages}
                    color="primary"
                  >
                    {t("common.seeMore")}
                  </Button>
                </Box>
              )}
            </Fragment>
          ) : (
            <Typography textAlign="center" color="textSecondary">
              Không tìm thấy kết quả
            </Typography>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            fullWidth
            startIcon={<DoneIcon />}
            className="text-transform"
            variant="contained"
            autoFocus
            disabled={state.fanpage_selected.length === 0}
            onClick={handleConfirm}
            color="primary"
          >
            {t("common.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogSelectFanpages;
