import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from "@mui/material"
import { useEffect, useState } from "react"
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import api from './../../../../../../../Apis'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next"

function Product(props) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        products: [],
        loading: false
    });

    const {register, handleSubmit, reset, setValue, control, getValues , watch} = useForm();
    const selectedProducts = watch('products', []);
   
    

    useEffect(() => {
        getProduct();
    }, [])

    const onPickProduct = (data) => {
        if(!Array.isArray(data.products)) {
            props.addToCart([data.products]);
        } else {
            if (data.products.length == 0) {
                Swal.fire({
                    title: 'Chưa chọn sản phẩm',
                    icon: 'warning',
                    timer: 1000
                })
            } else {
                // console.log(data.products);
                props.addToCart(data.products);
            }
        } 
    }
    
    const getProduct = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            }
        };
        fetch(api.list_product + `?lang=${i18n.language}`, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success == true) {
                        setState({
                            ...state,
                            products: result.datas,
                            loading: false
                        });
                    }
                }
            )
    }

    const refreshProduct = () => {
        setState({...state, loading: true});
        getProduct();
    }



    const addToCart = (detail_product, product) => {
        const cart = {
            // product_id: product.name,
            "product_name": product.name,
            "product_type_group": product.product_type_groups.name,
            "product_type": product.product_type.name,
            "product_type_name": detail_product.name,
            "detail_product_name": detail_product.name,
            // detail_product_id: detail_product.id,
            "price": detail_product.price,
            "max_number": detail_product.number,
            "number": 1,
            "weight": product.weight,
            "type_name": ''
        }
        props.addToCart(cart);
    }

    const addToCart_V2 = (type_list, product, detail_product) => {
        const cart = {
            // product_id: product.name,
            "product_name": product.name,
            "product_type_group": product.product_type_groups.name,
            "product_type": product.product_type.name,
            "detail_product_name": detail_product.name,
            "product_type_name": type_list.name,
            "type_name": type_list.name,
            // detail_product_id: detail_product.id,
            "number": 1,
            "price": type_list.price,
            "max_number": type_list.number,
            "weight": product.weight
        }
        props.addToCart(cart);
    }
    // const products = state.products != '' ? state.products : [];

    return (
        <TableContainer component={Paper}>
            <form>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Button type="button" onClick={refreshProduct} startIcon={<RotateLeftRoundedIcon/>} color="success" sx={{textAlign: "left", textTransform: "unset"}} size="small" variant="contained">
                                    {t("common.reload")}
                                </Button>
                                <Button type="button" href="/dashboard/product" target="_blank" onClick={() => setState({...state, dialog: true})} startIcon={<AddRoundedIcon/>} sx={{textAlign: "left", textTransform: "unset", marginLeft: "10px"}} size="small" variant="contained">
                                    {t("common.add")}
                                </Button>
                                <Button onClick={handleSubmit(onPickProduct)} disabled={!selectedProducts || selectedProducts.length === 0}  type="button" color="warning" startIcon={<CheckRoundedIcon/>} sx={{textAlign: "left", textTransform: "unset", marginLeft: "10px"}} size="small" variant="contained">
                                    {t("order.selectChosenProduct")}
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t("order.choose")}</TableCell>
                            <TableCell>
                                {t("order.productCode")}
                            </TableCell>
                            <TableCell>
                                {t("order.productName")}
                            </TableCell>
                            <TableCell>
                                {t("order.attribute")}
                            </TableCell>
                            <TableCell>
                               {t("order.weightInGrams")}
                            </TableCell>
                            <TableCell>
                                {t("order.sellingPrice")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            state.loading ?
                            <TableRow>
                                <TableCell align="center" colSpan={6}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                            :
                            state.products.map((productItem, index) => {
                                return productItem.detail_products.map((value, key) => {
                                    return (
                                        <TableRow>
                                            <TableCell>
                                                <input {...register(`products`)} defaultValue={JSON.stringify(value)} type="checkbox" style={{
                                                    width: 20,
                                                    height: 20,
                                                }}/>
                                            </TableCell>
                                            <TableCell>{productItem.code}</TableCell>
                                            <TableCell>{productItem.name}</TableCell>
                                            <TableCell>{value.attribute}</TableCell>
                                            <TableCell><b>{Number(value.weight).toLocaleString()}</b></TableCell>
                                            <TableCell><b>{Number(value.price).toLocaleString()}</b></TableCell>
                                        </TableRow>
                                    )
                                })
                            })
                        }
                    </TableBody>
                </Table>
            </form>
        </TableContainer>
    )
}

export default Product;