import React, { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";

const SearchFanpageForm = ({ onSearch, onClear }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  const onHandleClear = () => {
    setSearchTerm("");
    onClear();
  };
  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item xs={8}>
        <TextField
          autoComplete="off"
          fullWidth
          size="small"
          id="outlined-basic"
          label={t("common.searchFanpageLabel")}
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearch(searchTerm);
            }
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Button
          type="submit"
          className="text-transform"
          startIcon={<SearchIcon />}
          variant="contained"
          onClick={() => onSearch(searchTerm)}
        >
          {t("common.search")}
        </Button>
        <Button
          sx={{ marginLeft: "5px" }}
          type="submit"
          className="text-transform"
          startIcon={<RefreshIcon />}
          variant="contained"
          color="warning"
          disabled={!searchTerm}
          onClick={onHandleClear}
        >
          {t("common.refresh")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchFanpageForm;
