import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from './../../../../Apis';
import Tooltip from '@mui/material/Tooltip';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import AddFanpge from './../../../../Assets/Images/add-svg.svg'
import { Dialog, DialogContent, DialogTitle, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, AlertTitle, Alert } from '@mui/material';
import PageConnect from './Components/PageConnect';
import Swal from 'sweetalert2';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FanpageItem from './Components/FanpageItem';
import LoginWithFacebook from '../../../Globals/LoginWithFacebook';
import { useForm } from 'react-hook-form';
import { requestOptions, search } from '../../../../Helpers/function';
import Search from './Components/Search';
import SearchFanpageInActive from './Components/SearchFanpageInActive';
import Notification from './Components/Notification';
import Noti from './Components/Noti';
import RowFanpageItem from './Components/RowFanpageItem';
import RowFanpageItemNotConnect from './Components/RowFanpageItemNotConnect';
import ButtonDeleteAllFanpage from './Components/ButtonDeleteAllFanpage';
import { useTranslation, Trans } from 'react-i18next'
function Index() {
    const { t , i18n } = useTranslation();
    const appName = process.env.REACT_APP_NAME;
    const [cookies, setCookie] = useCookies('is_login');
    const [state, setState] = useState({
        fanpage_actives: [],
        fanpage_active_searchs: [],
        instagrams: [],
        instagram_searchs: [],
        fanpage_connects: [],
        fanpage_connect_searchs: [],
        open: true,
        error: '',
        dialog: false,
        total_page_connected: 0,
        phone: '',
        shop_name: '',
        value_tab: 'facebook',
        notification: null,
        limit_fanpage_active: 10,
        limit_fanpage_connect: 10
    });

    const {register, handleSubmit, reset} = useForm();
   
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            }
        };
        fetch(api.get_fanpage + '?noti_ids=' + (localStorage.getItem("noti_ids") == null ? '' : localStorage.getItem("noti_ids")) + `&lang=${i18n.language}`, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    const fanpage_actives = result.datas.fanpage_actives;
                    const fanpage_connects = result.datas.fanpage_connects.filter((item) => item.status == 0);
                    const instagrams = result.datas.instagrams;
                    setState({
                        ...state,
                        fanpage_actives: fanpage_actives,
                        fanpage_active_searchs: fanpage_actives,
                        fanpage_connects: fanpage_connects,
                        fanpage_connect_searchs: fanpage_connects,
                        instagrams: instagrams,
                        instagram_search: instagrams,
                        total_page_connected: result.datas.total_page_connected,
                        open: false,
                        phone: result.datas.phone,
                        shop_name: result.datas.shop_name,
                        notification: result.datas.notification
                    });
                } else {
                    setState({
                        ...state,
                        error: result.message,
                        open: false
                    });
                }
            }
        )
    }, [])

    const addFanpageActive = (new_fanpage, new_insta) => {
        setState({
            ...state,
            fanpage_actives: new_fanpage != null ? [...state.fanpage_actives, new_fanpage] : [...state.fanpage_actives],
            instagrams: new_insta != null ? [...state.instagrams, new_insta] : [...state.instagrams]
        })
    }

    const addMutipleFanpageActive = (new_fanpage_array, new_insta_array) => {
        setState({
            ...state,
            fanpage_actives: new_fanpage_array.length > 0 ? new_fanpage_array.concat(state.fanpage_actives) : [...state.fanpage_actives],
            instagrams: new_insta_array.length > 0 ? new_insta_array.concat(state.instagrams) : [...state.instagrams]
        });
    }

    function responseFacebook(result) {
        setState({
            ...state,
            fanpage_connects: result.datas
        })
        Swal.fire(result.message, '', 'success');
    }

    const remove = (index) => {
        const fanpage_actives = [...state.fanpage_actives];
        fanpage_actives.splice(index, 1);
        setState({
            ...state,
            fanpage_actives: fanpage_actives,
        })
    }

    const runOrPauseReciveComment = (data, index, status) => {
        Swal.fire({
            title: t("common.alert.confirmationQuestion"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("common.alert.confirm"),
            cancelButtonText: t("common.alert.cancel")
        }).then((result) => {
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.is_login
                    },
                    body: JSON.stringify({
                        fanpage_id: data.fanpage_id,
                        status: status,
                        lang: i18n.language
                    })
                };
                fetch(api.run_and_pause_recive_comment, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const copy_fanpage_actives = [...state.fanpage_actives];
                            copy_fanpage_actives[index].value_status_comment = status;
                            setState({
                                ...state,
                                fanpage_actives: copy_fanpage_actives
                            })
                            Swal.fire(result.message, '', 'success');
                        } else {
                            Swal.fire(result.message, '', 'error');
                        }
                    }
                )
            }
        })
    }

    const runOrPauseReciveMessage = (data, index, status) => {
        Swal.fire({
            title: t("common.alert.confirmationQuestion"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("common.alert.confirm"),
            cancelButtonText: t("common.alert.cancel")
          }).then((result) => {
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.is_login
                    },
                    body: JSON.stringify({
                        fanpage_id: data.fanpage_id,
                        status: status,
                        lang: i18n.language
                    })
                };
                fetch(api.run_and_pause_recive_message, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const copy_fanpage_actives = [...state.fanpage_actives];
                            copy_fanpage_actives[index].value_status = status;
                            copy_fanpage_actives[index].class = status ? 'status-in-active' : 'status-active';
                            copy_fanpage_actives[index].status = status ? 'Ngắt kết nối' : 'Hoạt động';
                            setState({
                                ...state,
                                fanpage_actives: copy_fanpage_actives
                            })
                            Swal.fire(result.message, '', 'success');
                        } else {
                            Swal.fire(result.message, '', 'error');
                        }
                    }
                )
            }
          })
    }

    const loadFanpage = (name, type_page) => {
        if (name != '') {
            if (type_page == 'page_active') {
                if (state.value_tab == 'facebook') {
                    setState({
                        ...state,
                        fanpage_actives: search(name, state.fanpage_active_searchs, 'fanpage_name'),
                        open: false
                    });
                } else if (state.value_tab == 'instagram') {
                    setState({
                        ...state,
                        instagrams: search(name, state.instagram_searchs, 'fanpage_name'),
                        open: false
                    });
                }
            } else if (type_page = 'page_in_active') {
                setState({
                    ...state,
                    fanpage_connects: search(name, state.fanpage_connect_searchs, 'fanpage_name')
                })
            }
        } else {
            if (type_page == 'page_active') {
                if (state.value_tab == 'facebook') {
                    setState({
                        ...state,
                        fanpage_actives: [...state.fanpage_active_searchs],
                        open: false
                    });
                } else if (state.value_tab == 'instagram') {
                    setState({
                        ...state,
                        instagrams: [...state.instagram_searchs],
                        open: false
                    });
                }
            } else if (type_page = 'page_in_active') {
                setState({
                    ...state,
                    fanpage_connects: [...state.fanpage_connect_searchs]
                })
            }
        }
    }

    const loadMoreFanpage = () => {
        const new_limit = parseInt(state.limit_fanpage_active) + 12;
        setState({
            ...state,
            limit_fanpage_active: new_limit
        })
    }

    const loadMoreFanpageConnect = () => {
        const new_limit = parseInt(state.limit_fanpage_connect) + 12;
        setState({
            ...state,
            limit_fanpage_connect: new_limit
        })
    }

    const connected = (index, data) => {
        const fanpage_connect_old = [...state.fanpage_connects];
        fanpage_connect_old.splice(index, 1);
        const fanpage_connect_search_old = [...state.fanpage_connect_searchs];
        fanpage_connect_search_old.splice(index, 1);
        setState({
            ...state,
            fanpage_actives: [data, ...state.fanpage_actives],
            fanpage_active_searchs: [data, ...state.fanpage_active_searchs],
            fanpage_connects: fanpage_connect_old,
            fanpage_connect_searchs: fanpage_connect_search_old
        })
    }

    const deleteAllPage = () => {
        setState({...state, fanpage_actives: []});
    }
        
    return (
        <Container maxWidth="xl" className='m-t-40'>
            
            {
                state.open
                &&
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={state.open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                
            }
            {
                state.notification != null &&
                <Notification
                    notification={state.notification}
                />
            }
            <Dialog open={state.dialog} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog: false})}}>
                <DialogTitle>
                    {t('dashboard.dialog.socialMedia')} - {state.shop_name}
                </DialogTitle>
                <br></br>
                <small style={{padding: '0px 24px'}}>
                    {/* <b style={{color: 'red'}}>Chú ý: </b>Nếu bạn chọn nhiều Fanpage kết nối cùng một lúc thì quá trình này sẽ mất vài phút hoặc lâu hơn tùy thuộc vào số lượng Page của bạn. */}
                    <Trans 
                        i18nKey={"dashboard.dialog.attention"}
                        components={{
                            1: <b style={{color: 'red'}} />,
                        }}
                    />
                </small>
                <SearchFanpageInActive
                    cookie={cookies.is_login}
                    lang={i18n.language}
                    loadFanpage={loadFanpage}
                />
                <PageConnect
                    addFanpageActive={addFanpageActive}
                    addMutipleFanpageActive={addMutipleFanpageActive}
                    dialog={state.dialog}
                    cookie={cookies.is_login}
                    fanpage_connects={state.fanpage_connects}
                    value_tab={state.value_tab}
                />
            </Dialog>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Noti
                        cookies={ cookies }
                    />
                    {
                        process.env.REACT_APP_DOMAIN != "page5s.com" &&
                        <Grid item xs={12} md={12}>
                            <Alert variant="filled" severity="success">
                                <AlertTitle>{t("dashboard.notification.title")}</AlertTitle>
                                {/* Đối với những khách hàng mới vui lòng truy cập vào <strong>
                                    <a style={{color: "#fff"}} href={`https://app.page5s.com`}>https://app.page5s.com</a>
                                </strong> để tiếp tục sử dụng dịch vụ. Những khách hàng cũ vẫn tiếp tục sử dụng bình thường trên <strong>{ process.env.REACT_APP_NAME }</strong> hoặc chuyển sang <strong><a style={{color: "#fff"}} href={`https://page5s.com`}>Page5s</a></strong> nếu muốn. Mọi thắc mắc vui lòng liên hệ qua số <strong>0963 108 271</strong> */}
                                <Trans
                                    i18nKey={"dashboard.notification.content"}
                                    components={{
                                        strong: <strong />,
                                        a: <a style={{ color: '#fff' }} />
                                      }}
                                      values={{ appName }}
                                />
                            </Alert>
                        </Grid>
                    }
                    <Grid item xs={12} md={12}>
                        <TabContext value={state.value_tab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={(e, new_value) => setState({...state, value_tab: new_value})} aria-label="lab API tabs example">
                                    <Tab sx={{color: "#fff !important", background: state.value_tab == 'facebook' ? '#1976d2' : '#696969'}} className='text-transform' 
                                    // label={`Đã kết nối ${state.fanpage_actives.length} fanpage`} 
                                        label={t("dashboard.tabs.tabTitle1", { count: state.fanpage_actives.length })}
                                        value="facebook"
                                     />
                                    <Tab sx={{color: "#fff !important", background: state.value_tab == 'instagram' ? '#1976d2' : '#696969'}} className='text-transform' 
                                    // label={`${state.fanpage_connects.length} Fanpage chưa kết nối`} 
                                        label={t("dashboard.tabs.tabTitle2", { count: state.fanpage_connects.length })}
                                        value="instagram" 
                                    />
                                </TabList>
                            </Box>
                            <TabPanel style={{padding: '30px 0px'}} value="instagram">
                                {/* <Search
                                    loadFanpage={loadFanpage}
                                /> */}
                                <SearchFanpageInActive
                                    cookie={cookies.is_login}
                                    lang={i18n.language}
                                    loadFanpage={loadFanpage}
                                />
                                <br></br>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t('dashboard.table.no')}</TableCell>
                                                <TableCell>{t('dashboard.table.id')}</TableCell>
                                                <TableCell>{t('dashboard.table.fanpageName')}</TableCell>
                                                <TableCell align="center">{t('dashboard.table.action.title')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                state.fanpage_connects.slice(0, state.limit_fanpage_connect).map((value, key) => {
                                                        return (
                                                            <RowFanpageItemNotConnect
                                                                data={ value }
                                                                cookies={ cookies }
                                                                connected={ connected }
                                                                status={ value.status }
                                                                key={ key }
                                                                index={ key }
                                                                stt={ key + 1 }
                                                            />
                                                        )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {
                                    state.fanpage_connects.length > state.limit_fanpage_connect &&
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <p style={{textAlign: 'center'}}>
                                                <Button variant='contained' onClick={loadMoreFanpageConnect}>
                                                   {t("common.seeMore")}
                                                </Button>
                                            </p>
                                        </Grid>
                                    </Grid>
                                }
                                {/* <Grid container spacing={2}>
                                    {
                                        <Grid item xs={6} md={2}>
                                            <div className='fanpage-item add-fanpage'>
                                                <Tooltip arrow onClick={() => setState({...state, dialog: true})} title='Kết nối page mới' placement="top">
                                                    <img src={AddFanpge}></img>
                                                </Tooltip>
                                                <p>
                                                    Đã kết nối {state.instagrams.length} tài khoản Instagram Business
                                                </p>
                                            </div>
                                        </Grid>
                                    }
                                    {
                                        state.instagrams.length > 0 &&
                                        state.instagrams.map((value, key) => {
                                            return (
                                                <FanpageItem
                                                    data={value}
                                                    social_name="instagram"
                                                    runOrPauseReciveMessage={runOrPauseReciveMessage}
                                                    runOrPauseReciveComment={runOrPauseReciveComment}
                                                    index={key}
                                                    remove={remove}
                                                    title_pause="Dừng nhận tin nhắn Instagram"
                                                    title_play="Kết nối nhận tin nhắn Instagram"
                                                />
                                            )
                                        })
                                    }
                                </Grid> */}
                            </TabPanel>
                            <TabPanel style={{padding: '30px 0px'}} value="facebook">
                                <Search
                                    loadFanpage={ loadFanpage }
                                    responseFacebook={responseFacebook}
                                />
                                <br></br>
                                <ButtonDeleteAllFanpage
                                    cookies={ cookies }
                                    deleteAllPage={ deleteAllPage }
                                />
                                <br></br>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                            <TableCell>{t('dashboard.table.no')}</TableCell>
                                                <TableCell>{t('dashboard.table.id')}</TableCell>
                                                <TableCell>{t('dashboard.table.fanpageName')}</TableCell>
                                                <TableCell align="center">{t('dashboard.table.action.title')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                state.fanpage_actives.slice(0, state.limit_fanpage_active).map((value, key) => {
                                                    return (
                                                        <RowFanpageItem
                                                            data={ value }
                                                            cookies={ cookies }
                                                            remove={ remove }
                                                            key={ key }
                                                            index={ key }
                                                            stt={ key + 1 }
                                                        />
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <Grid container spacing={2}>
                                    {
                                        <Grid item xs={6} md={2}>
                                            <div className='fanpage-item add-fanpage'>
                                                <Tooltip arrow onClick={() => setState({...state, dialog: true})} title='Kết nối page mới' placement="top">
                                                    <img src={AddFanpge}></img>
                                                </Tooltip>
                                                <p>
                                                    Đã kết nối {state.fanpage_actives.length} Fanpages
                                                </p>
                                            </div>
                                        </Grid>
                                    }
                                    {
                                        state.error != ''
                                        ?
                                            <Grid item xs={12} md={12}>
                                                <p className='t-center'>
                                                    {state.error} <br></br><br></br>
                                                    <Button variant="contained">
                                                    <RestartAltRoundedIcon></RestartAltRoundedIcon>
                                                        <a className='reload' href='/dashboard'>
                                                            Tải lại
                                                        </a>
                                                    </Button>
                                                </p>
                                            </Grid>
                                        :    
                                        state.fanpage_actives.slice(0, state.limit_fanpage_active).map((value, key) => {
                                            return (
                                                <FanpageItem
                                                    social_name="facebook"
                                                    data={value}
                                                    index={key}
                                                    runOrPauseReciveComment={runOrPauseReciveComment}
                                                    remove={remove}
                                                    runOrPauseReciveMessage={runOrPauseReciveMessage}
                                                    title_pause="Dừng nhận tin nhắn từ Facebook"
                                                    title_play="Kết nối nhận tin nhắn từ Facebook"
                                                />
                                            )
                                        })
                                    }
                                </Grid> */}
                                {
                                    state.fanpage_actives.length > state.limit_fanpage_active &&
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <p style={{textAlign: 'center'}}>
                                                <Button variant='contained' onClick={loadMoreFanpage}>
                                                   {t("common.seeMore")}
                                                </Button>
                                            </p>
                                        </Grid>
                                    </Grid>
                                }
                            </TabPanel>
                        </TabContext>
                    </Grid>
                </Grid>
                <br></br>
                <Grid style={{justifyContent: 'center'}} container spacing={2}>
                    <Grid item xs={6} md={6} style={{textAlign: "center"}}>
                        <h2>
                            {t("dashboard.help.title")}
                        </h2>
                        <p>
                            {t("dashboard.help.refreshPermissions")}
                        </p>
                        <p>
                            <LoginWithFacebook
                                page="home"
                                title={t("dashboard.help.refreshConnection")}
                                responseFacebook={responseFacebook}
                                customClass="css-fb-custom"
                                size="large"
                            />
                        </p>
                        <p>
                            <Trans
                                i18nKey={"dashboard.help.contactInfo"}
                                components={{
                                    b: <b />,
                                }}
                                values={{ phone: state.phone, appName: process.env.REACT_APP_NAME }}
                            />
                            
                            {/* Mọi thắc mắc vui lòng liên hệ <b>{state.phone}</b> hoặc tham gia nhóm hỗ trợ Group { process.env.REACT_APP_NAME } */}
                        </p>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default Index;