import { Button, Stack, TableCell, TableRow, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import Swal from "sweetalert2";
import api from "../../../../../Apis";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from 'react-i18next'

export default function RowFanpageItem(props) {
    const { data, stt, index, cookies } = props;
    const { t, i18n } = useTranslation();
    const navigation = useNavigate();
    const [state, setState] = useState({
        loading_delete: false,
        loading_cmt: false,
        loading_inbox: false,
        status_button_recive_cmt: data?.value_status_comment,
        status_button_recive_msg: data?.value_status,
    })
    // const runOrPauseReciveMessage = (data, index, status) => {
    //     props.runOrPauseReciveMessage(data, index, status)
    // }

    const runOrPauseReciveMessage = (data, index, status) => {
        Swal.fire({
            title: t("common.alert.confirmationQuestion"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("common.alert.confirm"),
            cancelButtonText: t("common.alert.cancel")
          }).then((result) => {
            if (result.isConfirmed) {
                setState({...state, loading_inbox: true});
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.is_login
                    },
                    body: JSON.stringify({
                        fanpage_id: data.fanpage_id,
                        status: status,
                        lang: i18n.language
                    })
                };
                fetch(api.run_and_pause_recive_message, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            setState({...state, loading_inbox: false, status_button_recive_msg: status});
                            Swal.fire(result.message, '', 'success');
                        } else {
                            setState({...state, loading_inbox: false});
                            Swal.fire(result.message, '', 'error');
                        }
                    }
                )
            }
          })
    }
    const runOrPauseReciveComment = (data, index, status) => {
        Swal.fire({
            title: t("common.alert.confirmationQuestion"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("common.alert.confirm"),
            cancelButtonText: t("common.alert.cancel")
        }).then((result) => {
            if (result.isConfirmed) {
                setState({
                    ...state,
                    loading_cmt: true
                })
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.is_login
                    },
                    body: JSON.stringify({
                        fanpage_id: data.fanpage_id,
                        status: status,
                        lang: i18n.language
                    })
                };
                fetch(api.run_and_pause_recive_comment, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            // const copy_fanpage_actives = [...state.fanpage_actives];
                            // copy_fanpage_actives[index].value_status_comment = status;
                            // setState({
                            //     ...state,
                            //     fanpage_actives: copy_fanpage_actives
                            // })
                            setState({
                                ...state,
                                loading_cmt: false,
                                status_button_recive_cmt: status
                            })
                            Swal.fire(result.message, '', 'success');
                        } else {
                            setState({
                                ...state,
                                loading_cmt: false
                            })
                            Swal.fire(result.message, '', 'error');
                        }
                    }
                )
            }
        })
    }

    const remove = (data, index) => {
        Swal.fire({
            title: t("common.alert.confirmationQuestion"),
            text: t("dashboard.dataDeletionWarning"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('common.alert.confirm'),
            cancelButtonText: t('common.alert.cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                setState({
                    ...state,
                    loading_delete: true
                })
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.is_login
                    },
                    body: JSON.stringify({
                        fanpage_id: data.fanpage_id,
                        lang: i18n.language
                    })
                };
                fetch(api.remove, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            props.remove(index);
                            Swal.fire({
                                // title: result.message,
                                title: t("common.alert.deleteSuccess"),
                                icon: 'success',
                                timer: 1500
                            })
                            setState({
                                ...state,
                                loading_delete: false
                            })
                        } else {
                            setState({
                                ...state,
                                loading_delete: false
                            })
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                timer: 1500
                            })
                        }
                    }
                )
            }
        })
    }
    
    return (
        <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell>{ stt }</TableCell>
            <TableCell>
                <a href={`https://www.facebook.com/${ data.fanpage_id }`} target="_blank">
                    { data.fanpage_id }
                </a>
            </TableCell>
            <TableCell>
                <b>{ data.fanpage_name }</b>
            </TableCell>
            {/* <TableCell>{ data.fanpage_like }</TableCell> */}
            <TableCell align="center">
                <Stack sx={{ marginBottom: "5px" }} spacing={1} direction="row" justifyContent="center" flexWrap="wrap">
                    <Button className="text-transform" color="success" onClick={() => navigation("/dashboard/fanpage/" + props.data.fanpage_id + '/bai-viet')} variant="contained">{t("dashboard.table.action.post")}</Button>
                    <Button className="text-transform" color="primary" onClick={() => navigation(`/dashboard/${props.data.fanpage_id}/chatbot`)} variant="contained">Chatbot</Button>
                    <Button className="text-transform" color="info" onClick={() => navigation(data.fanpage_id + "/customer")} variant="contained">{t("dashboard.table.action.customers")}</Button>
                </Stack>
                <Stack spacing={1} direction="row" justifyContent="center">
                    <LoadingButton loading={ state.loading_inbox } className="text-transform" color={`${state.status_button_recive_msg ? 'warning' : 'inherit'}`} onClick={() => runOrPauseReciveMessage(data, index, !state.status_button_recive_msg)} variant="contained">{ state.status_button_recive_msg ? t("dashboard.table.action.disableInbox") : t("dashboard.table.action.enableInbox") }</LoadingButton>
                    
                    <LoadingButton loading={ state.loading_cmt } className="text-transform" color={`${state.status_button_recive_cmt ? 'secondary' : 'inherit'}`} onClick={() => runOrPauseReciveComment(data, index, !state.status_button_recive_cmt)} variant="contained">{ state.status_button_recive_cmt ? t("dashboard.table.action.disableComment") : t("dashboard.table.action.enableComment") }</LoadingButton>
                    
                    <LoadingButton loading={ state.loading_delete } className="text-transform" color="error" onClick={() => remove(data, index)} variant="contained">{t("common.delete")}</LoadingButton>

                </Stack>
            </TableCell>
        </TableRow>
    )
}