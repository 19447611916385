import { Dialog, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function ListOrder(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        dialog: false,
        code: '',
        detail_orders: []
    })

    const detailOrder = (order) => {
        setState({...state, dialog: true, code: order.code, detail_orders: order.detail_orders});
    }

    return (
        <TableContainer component={Paper}>
            <Dialog open={state.dialog} fullWidth="true" maxWidth='md' onClose={() => {setState({...state, dialog: false})}}>
                <DialogTitle>{t("liveChat.tabContent.orderDetail")} <b>{state.code}</b></DialogTitle>
                <DialogContent>
                    <Table aria-label="simple table" className="table-cart">
                        <TableHead>
                            <TableRow>
                                <TableCell className="nowrap">{t("order.productName")}</TableCell>
                                <TableCell className="nowrap">{t("liveChat.tabContent.type")}</TableCell>
                                <TableCell className="nowrap" align="center">{t("liveChat.tabContent.sl")}</TableCell>
                                <TableCell className="nowrap" align="right">{t("order.priceVND")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                state.detail_orders.map((productItem, key) => {
                                    return (
                                        <TableRow>
                                            <TableCell className="nowrap">{productItem.product_name}</TableCell>
                                            <TableCell align="left" className="nowrap">
                                                <p>
                                                    {productItem.product_type_group}: <b>{productItem.detail_product_name}</b>
                                                </p>
                                                {
                                                    (productItem.product_type != null && productItem.product_type != '') &&
                                                    <p>
                                                        {productItem.product_type}: <b>{productItem.product_type_name}</b>
                                                    </p>
                                                }
                                            </TableCell>
                                            <TableCell align="center">{productItem.amount}</TableCell>
                                            <TableCell align="right">
                                                <b>
                                                    {Number(productItem.price).toLocaleString()}
                                                </b>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                            
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
            <Table aria-label="simple table" className="table-cart">
                <TableHead>
                    <TableRow>
                        <TableCell className="nowrap">{t("common.dateTime.day")}</TableCell>
                        <TableCell className="nowrap">{t("order.table.orderCode")}</TableCell>
                        {/* <TableCell className="nowrap" align="center">Chi tiết</TableCell> */}
                        <TableCell className="nowrap" align="right" style={{
                            minWidth: 100
                        }}>{t("liveChat.tabContent.totalPrice")}</TableCell>
                        <TableCell className="nowrap" align="center" style={{
                            minWidth: 100
                        }}> {t("order.table.status")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.orders.map((item, key) => (
                        <TableRow
                        key={key}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >   
                            <TableCell component="th" scope="row">
                                {item.created_at}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <span onClick={() => detailOrder(item)} className="cursor label label-primary"> 
                                    {item.code}
                                </span>
                            </TableCell>
                            
                            <TableCell align="center">
                                {Number(item.total_price).toLocaleString()}
                            </TableCell>
                            <TableCell align="center">
                                <span className={"label label-" + item.status.color}>
                                    {item.status.name}
                                </span>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ListOrder;