import { Button, Grid, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from "react-i18next"
import LoginWithFacebook from "../../../../Globals/LoginWithFacebook";
import AddIcon from '@mui/icons-material/Add';
export default function Search(props) {
    const {t} = useTranslation();
    const {register, handleSubmit, reset} = useForm();

    const searchFanpage = (data) => {
        props.loadFanpage(data.name, 'page_active');
    }

    const reloadFanpage = () => {
        reset();
        props.loadFanpage('', 'page_active');
    }

    return (
        <form onSubmit={handleSubmit(searchFanpage)}>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <TextField {...register('name')} autoComplete='off' fullWidth size="small" id="outlined-basic" label={t('common.searchFanpageLabel')} variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <Button type="submit" className='text-transform' startIcon={<SearchIcon/>} variant="contained">{t('common.search')}</Button>
                    <Button onClick={reloadFanpage} sx={{margin: '0 5px'}} type="button" className='text-transform' color="error" startIcon={<ReplayIcon/>} variant="contained">{t('common.reload')}</Button>
                    <LoginWithFacebook title={t('common.addFanpage')} className='text-transform' color={"warning"} icon={<AddIcon/>} responseFacebook={props.responseFacebook} />
                </Grid>
            </Grid>
        </form>
    )
}