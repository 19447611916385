import {
	BrowserRouter,
	Routes as Switch,
	Route
  } from 'react-router-dom';
import Header from './../Components/Dashboards/Layouts/Header';
import Footer from './../Components/Dashboards/Layouts/Footer';
import Home from './../Components/Dashboards/Pages/Homes/Index.js'
import Fanpage from './../Components/Dashboards/Pages/Fanpages/Index.js'
import Post from './../Components/Dashboards/Pages/Fanpages/Posts/Index.js'
import Webhook from './../Components/Dashboards/Pages/Fanpages/Webhook/Index.js'
import ChatRealTime from './../Components/Dashboards/Pages/Fanpages/ChatRealTime/Index.js'
import Message from './../Components/Dashboards/Pages/Fanpages/Messages/Index.js'
import GroupFanpage from '../Components/Dashboards/Pages/Fanpages/Groups/Index.js'
import './../Css/dashboard.scss'
import List from './../Components/Dashboards/Pages/Products/Index';
import Setting from './../Components/Dashboards/Pages/Settings/Index';
import ChangeShopActive from './../Components/Dashboards/Pages/Shop/ChangeShopActive';
import Order from './../Components/Dashboards/Pages/Orders/Index';
import OrderDetail from './../Components/Dashboards/Pages/Orders/Components/OrderDetail';
import Price from '../Components/Dashboards/Pages/Prices/Index';
import Customer from '../Components/Dashboards/Pages/Customers/Index';
import { useEffect } from 'react';
import { useCookies } from "react-cookie";
import Revenue from './../Components/Dashboards/Pages/Revenues/Index';
import Expense from './../Components/Dashboards/Pages/Expenses/Index';
import Bank from './../Components/Dashboards/Pages/Banks/Index';
import Transaction from './../Components/Dashboards/Pages/Transactions/Index';
import CheckPhoneNumber from './../Components/Dashboards/Pages/CheckPhoneNumbers/Index';
import CalculatorFee from './../Components/Dashboards/Pages/CalculatorFees/Index';
import LiveChat from './../Components/Dashboards/Pages/Fanpages/Messages/Index';
import {
  useNavigate
} from "react-router-dom";
import ChatBot from '../Components/Dashboards/Pages/ChatBots/Index';
import ReactSelectFast from '../Components/Dashboards/ReactSelectFast.js';
import Pricing from '../Components/Dashboards/Pages/Pricing/Index.js';
function DashboardRoute() {
  const [cookies, setCookie, removeCookie] = useCookies(['is_login']);
  let navigate = useNavigate();
  
  useEffect(() => {
    if (cookies.is_login == undefined) {
      return navigate('/');
    }
  }, [])

	return (
        <div>
            <Header />
            <Switch>
                <Route path='/price' element={<Price/>}/>
                <Route path='pricing' element={<Pricing/>} />
                <Route path='fanpage' element={<Fanpage />} />
                <Route path='fanpage/group' element={<GroupFanpage/>}/>
                <Route path="chinh123" element={<ReactSelectFast/>}/>
                <Route path='revenue' element={<Revenue/>}></Route>
                <Route path='expense' element={<Expense/>}></Route>
                <Route path='chat' element={<ChatRealTime/>} />
                <Route path='/product' element={<List/>}/>
                <Route path='setting' element={<Setting/>}/>
                <Route path='order' element={<Order/>} />
                <Route path='fee' element={<CalculatorFee/>} />
                <Route path="live-chat" element={<LiveChat/>}/>
                <Route path='check-phone-number' element={<CheckPhoneNumber/>}/>
                <Route path=':fanpage_id/chatbot' element={<ChatBot/>}/>
                {/* <Route path="bank" element={<Bank/>}/> */}
                <Route path=":fanpage_id/customer" element={<Customer/>}/>
                <Route path=':id/order' element={<OrderDetail/>}/>
                <Route path='fanpage/:id/bai-viet' element={<Post/>} />
                {/* <Route path='transaction' element={<Transaction/>}/> */}
                <Route path='shop/:id' element={<ChangeShopActive/>}/>
                {/* <Route path='fanpage/:fanpage_id/setting' element={<Setting/>}/> */}
                <Route path='fanpage/:id/webhook-setting' element={<Webhook/>}/>
                <Route path='fanpage/:fanpage_id/tin-nhan' element={<Message/>}/>
                <Route path='/' element={<Home />} />
            </Switch>
        </div>
	)
}

export default DashboardRoute;